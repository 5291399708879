import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import isSubpage from '../utils/isSubpage';
import Subpage from '../components/Subpage';
import styles from './license.module.scss';

const LicensePage = ({ location }) => (
  <Layout isSubpage={isSubpage(location.pathname)}>
    <Seo title="License - Whoooa! Illustrations" />
    <Subpage className={styles.license} title="License">
      <article className={styles.license__article}>
        <h3>Licensing agreement </h3>
        <p>
          By purchasing the Whoooa! illustrations ("Item") you are being granted
          a license to use this file for specific uses under certain conditions.
        </p>
        <p>
          This license grants the user an ongoing, non-exclusive, worldwide
          license to utilize the digital work ("Item").
        </p>
        <p>
          You are licensed to use purchased Item to create unlimited End
          Products for yourself or for your clients and the End Product may be
          sold, licensed, sub-licensed or freely distributed.
        </p>
        <p>
          Please note: Only a full version of Whoooa! can be used in commercial
          products/projects. A free version of illustrations may be used for
          personal purposes only.
        </p>
        <h3>You are allowed to</h3>
        <ul>
          <li>
            This is a 'multi-use' license, which means you may use an Item
            multiple times, in multiple commercial and personal projects.
          </li>
          <li>
            Using the illustrations in templates or material that will be sold
            on marketplaces is allowed. However you cannot sell or redistribute
            Whoooa! illustrations as your own product, share source files with
            other people.
            <br />
            You are allowed to use illustrations in non-editable formats for
            demonstrating purposes of your own products.
          </li>
          <li>You may create an End Product for a client.</li>
          <li>You may create an End Product for personal or commercial use.</li>
          <li>
            Modify colours, style and the look & feel of the illustration to fit
            your brand, product and requirements.
          </li>
          <li>
            You may combine the Item with other works and make a derivative work
            from it. The resulting works are subject to the terms of this
            license.
          </li>
        </ul>
        <h3>You are not allowed to</h3>
        <ul>
          <li>
            Sell, redistribute, lease, license, sub-license Whoooa!
            illustrations as a stock, template, on marketplaces, etc. You cannot
            do this with an Item either on its own or bundled with other items,
            and even if you modify the Item. Even for free.
          </li>
          <li>Share a single license with multiple users.</li>
          <li>
            Modify the original artwork in order to resell under own brand.
          </li>
        </ul>
        <h3>Other terms</h3>
        <ul>
          <li>
            You are not required to attribute or link to Whoooa! illustrations
            in any of your projects.
          </li>
          <li>
            All the rights for fonts, photos, mockups, etc. used in Item are
            reserved by their legal owners and are presented in the Item for
            demonstrating purposes.
          </li>
          <li>
            We are not responsible for any outcome that may occur during the
            course of usage of our resources.
          </li>
        </ul>
        <h3>License termination</h3>
        <p>
          This license can be terminated at any time, if you breach it. If that
          happens, you must stop using the Item and delete it from your
          computers and other devices.
        </p>
      </article>
    </Subpage>
  </Layout>
);

export default LicensePage;
