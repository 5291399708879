import React from 'react';
import classnames from 'classnames';

import styles from './Subpage.module.scss';

const Subpage = ({ children, className, title }) => (
  <section className={classnames(className, styles.subpage)}>
    <header className={styles.subpage__heading}>
      <h1 className={styles.subpage__title}>{title}</h1>
    </header>
    {children}
  </section>
);

export default Subpage;
